<template>
  <li class="top-menu-item">
    <a-popover title="" placement="topRight" trigger="click">
      <template slot="content">
        <div class="tool-warp">
          <a-tabs default-active-key="1">
            <a-tab-pane key="1" tab="所有文件">
              <div class="tool-content">
                <file
                  v-for="(item, index) in apxs"
                  :key="item.AppendixId"
                  :modal="item"
                  :index="index"
                  @delete="deleteTool"
                  @preview="previewSource"
                ></file>
                <div v-if="apxs.length == 0">
                  <a-empty description="还没有上传工具" />
                </div>
              </div>
            </a-tab-pane>
            <a-tab-pane key="2" tab="上传文件">
              <a-alert
                message="每上传完一个文件请保存后再上传另一个文件"
                type="warning"
                show-icon
                description="仅支持小于500M的文件上传"
              />
              <br />
              <a-upload
                name="file"
                action="/api/MyLesson/UploadFiles"
                :file-list="fileList"
                :remove="removeFile"
                :before-upload="beforeFileUpload"
                @change="uploadHandleChange"
              >
                <a-button> <a-icon type="upload" /> 点击上传文件 </a-button>
              </a-upload>
              <br />
              <div>
                <a-input
                  addon-before="资源标签"
                  placeholder="请输入资源标签用于文件分组"
                  v-model="apxTag"
                />
              </div>
              <br />
              <div>
                <a-input
                  addon-before="文件重命名"
                  placeholder="请输入文件名称"
                  v-model="filename"
                />
              </div>
              <br />
              <div style="text-align: right">
                <a-button type="primary" @click="saveFile">保存</a-button>
              </div>
            </a-tab-pane>
            <a-tab-pane key="3" tab="文件链接" force-render>
              <br />
              <div>
                <a-input
                  addon-before="文件链接"
                  placeholder="请粘贴文件链接"
                  v-model="link"
                />
              </div>
              <br />
              <div>
                <a-input
                  addon-before="资源标签"
                  placeholder="请输入资源标签用于文件分组"
                  v-model="apxTag"
                />
              </div>
              <br />
              <div>
                <a-input
                  addon-before="链接名称"
                  placeholder="请输入链接名称"
                  v-model="linkname"
                />
              </div>
              <br />
              <div style="text-align: right">
                <a-button type="primary" @click="saveLink">保存</a-button>
              </div>
            </a-tab-pane>
            <a-tab-pane key="4" tab="历史删除">
              <div class="miss-content">
                <miss-file
                  v-for="(item, index) in missApxs"
                  :key="item.AppendixId"
                  :modal="item"
                  :index="index"
                  @recovery="recoveryAction"
                  @delete="permanentlyDelete"
                  @preview="previewMissApx"
                ></miss-file>

                <div
                  v-if="
                    missApxs.length == 0
                  "
                >
                  <a-empty description="没有历史删除文件" />
                </div>
              </div>
            </a-tab-pane>
          </a-tabs>
        </div>
      </template>
      <span>{{ text }}</span>
    </a-popover>
  </li>
</template>

<script>
import { delAliYunFile } from "@/api/user";
import MissFile from "./MissFile.vue";
import File from "./File.vue";
export default {
  name: "unit-interpretion-menu-item",
  components:{
      File,
      MissFile
  },
  props: {
    text: {
      type: String,
      default: "",
    },
    missingList: {
      type: Array,
      default: () => {
        return [];
      },
    },
    apxs:{
        type:Array,
        default:()=>{
            return[]
        }
    },
    missApxs:{
        type:Array,
        default:()=>{
            return[]
        }
    },
    index: {
      type: Number,
      default: 0,
    },
  },
  data() {
    return {
      visible: false,
      fileList: [],
      filename: "",
      linkname: "",
      link: "",
      apxTag: "",
    };
  },
  methods: {
      /**
     * 删除工具
     */
    deleteTool(params) {
      console.log(params);
      this.$emit("toolDelete", params);
    },
    /**
     * 恢复删除的工具
     */
    recoveryAction(params) {
      this.$emit("recoveryTool", params);
    },
    /**
     * 永久删除工具
     */
    permanentlyDelete() {
      this.$emit("permanently", params);
    },
    beforeFileUpload(file) {
      const isLt2M = file.size / 1024 / 1024 < 500;
      if (!isLt2M) {
        this.$message.error("文件大小必须小于500MB!");
      }
      return isLt2M;
    },
    previewSource(params){
        
      this.$emit('preview', params)
    },
    previewMissApx(params){
    
      this.$emit('previewmiss', params)
    },
    /**
     * 上传文件
     */
    uploadHandleChange(info) {
      // if (info.file.status !== "uploading") {
      //   console.log(info.file, info.fileList);
      //   this.fileList = info.fileList
      // }
      if (info.file.status === "done") {
        this.$message.success(`${info.file.name} 文件上传成功`);
      } else if (info.file.status === "error") {
        this.$message.error(`${info.file.name} 文件上传失败`);
      }

      let fileList = [...info.fileList];
      if (fileList.length > 1) {
        this.removeFile(fileList[0]);
      }
      // 1. Limit the number of uploaded files
      //    Only to show two recent uploaded files, and old ones will be replaced by the new
      fileList = fileList.slice(-1);
      // 2. read from response and show file link
      fileList = fileList.map((file) => {
        if (file.response) {
          // Component will show file.url as link
          file.url = file.response.url;
        }
        return file;
      });
      this.fileList = fileList;
    },
    removeFile(obj) {
      delAliYunFile({ key: obj.response[0].SourceUrl });
    },
    /**
     * 保存文件
     */
    saveFile() {
      if (this.fileList.length == 0) {
        this.$message.error("请上传文件");
        return;
      }
      let file = this.fileList[0].response[0];
      let params = {
        purpose: this.filename,
        ApxTag: this.apxTag,
        ...file,
      };
      this.$emit("uploadFile", params);
      this.fileList = [];
      this.filename = "";
      this.apxTag = "";
      this.visible = false;
    },
    /**
     * 保存链接
     */
    saveLink() {
      if (this.link.length == 0) {
        this.$message.error("请输入资源链接");
        return;
      }
      if (this.linkname.length == 0) {
        this.$message.error("请输入资源链接的教学用途（链接名称）");
        return;
      }
      let params = {
        linkUrl: this.link,
        ApxTag: this.apxTag,
        purpose: this.linkname,
      };
      this.$emit("uploadFile", params);
      this.link = "";
      this.linkname = "";
      this.apxTag = "";
      this.visible = false;
    },
    cancelUpload() {
      this.visible = false;
    },
  },
};
</script>

<style lang="less">
.top-menu-item {
    padding: 7Px 12Px;
    border-radius: 24Px;
    font-size: 12Px;
    background: #E8E9FF;
    border: 1px solid #BEC1FF;
    margin-left: 12Px;
    cursor: pointer;
}
.tool-warp {
width: 450Px;
min-height: 220Px;
}
</style>